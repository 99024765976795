import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { AndroidSetting } from '@root/app/shared/android-settings.types';
import { AppOptions } from '@root/app/shared/apps.types';
import { catchError, finalize, startWith, tap } from 'rxjs';

import { DevicesStore } from './devices.store';
import { SubscriptionGroup } from './devices.types';

@Injectable({
	providedIn: 'root',
})
export class DevicesService {
	readonly #devicesStore = inject(DevicesStore);
	readonly #apiService = inject(ApiService);

	appOptions = signal<AppOptions>({} as unknown as AppOptions);
	isLoading = signal<boolean>(false);
	settingsOptions = signal<AndroidSetting[]>([]);

	getAppOptions() {
		return this.#apiService.get<AppOptions>('/android/apps/appoptions').pipe(
			startWith({
				appStatuses: {},
				appTypes: {},
				vpnOptions: {},
				vpnBlockOptions: {},
				vpnDirectOptions: {},
			} as AppOptions),
			tap((data) => this.appOptions.set(data)),
		);
	}

	getDevices(customerId: string) {
		this.isLoading.set(true);

		return this.#apiService
			.get<SubscriptionGroup[]>(`/customer/getservicesbyworkorder/${customerId}`)
			.pipe(
				tap((data) => {
					this.isLoading.set(false);

					const safeData = Array.isArray(data) ? data : [];
					safeData.forEach((item) => {
						this.#devicesStore.addSubscription(item);
					});
				}),
				catchError(() => {
					this.isLoading.set(false);
					throw new Error('Error getting devices list');
				}),
			);
	}

	getSettingsOptions() {
		this.isLoading.set(true);

		return this.#apiService.get<AndroidSetting[]>('/android/device/getsettingoptions').pipe(
			finalize(() => this.isLoading.set(false)),
			tap((data) => this.settingsOptions.set(data)),
			catchError(() => {
				throw new Error('Error trying to get policy settings for restrictions, please try again.');
			}),
		);
	}

	importSLA(license: string, workOrder: number) {
		const options = { params: { workOrder }, responseType: 'text' };

		return this.#apiService.post(`/android/device/oldslaimport/${license}`, {}, options);
	}
}
